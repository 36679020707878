import {blackColor, blueColor, boxShadow, primaryColour, transition, whiteColor} from "assets/jss/material-dashboard-react";
import { grayColor } from "assets/jss/material-dashboard-react";
import HelpSectionLeft from 'assets/img/help-section-left.svg';
import HelpSectionRight from 'assets/img/help-section-right.svg';
import { colors } from "@material-ui/core";

const customPricingStyle = theme => ({
  pricingPanels: {
    marginTop: 20,
    marginBottom: 20,
    marginRight: 'auto',
    width: '100%',
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  planFooter: {
    marginTop: "auto", 
    marginBottom: 38,
    [theme.breakpoints.down(959.95)]: {
      marginTop: 36,
      marginBottom: 34,
    }
  },
  plansWrap: {
    maxWidth: 782
  },
  pricingPanelsDesktop: {
    width: 'unset',
    marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 'calc(50%)',
    },
    [theme.breakpoints.down(770)]: { // eslint-disable-line no-useless-computed-key
      marginRight: 15,
      maxWidth: 'calc(44%)',
    }
  },
  disabled: {
    '& span': {
      color: '#AEAEAE!important',
    }
  }, 
  contactUsButton: {
    backgroundColor: 'transparent',
    border: `2px solid ${primaryColour[700]}`,
    color: primaryColour[700],
    borderRadius: 8,
    maxWidth: 183,
    width: '100%',
    background: "transparent",
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down("xs")]: {
      backgroundColor: primaryColour[700],
      color: whiteColor,
      fontSize: 16,
      fontWeight: 'bold',
      width: '100%',
      '&:hover': {
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        backgroundColor: '#d5d5d5',
        border: '2px solid #d5d5d5',
      },
    }
  },
  demoButton: {
    color: whiteColor,
    marginLeft: 21,
    backgroundColor: primaryColour[700],
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      backgroundColor: primaryColour[700],
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: primaryColour[700],
      color: whiteColor,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      lineHeight: '18px',
      marginLeft: 0,
      width: '100%',
      '&:hover': {
        backgroundColor: '#00437C'
      }
    },
    '& .MuiSvgIcon-root': {
      height: 18
    }
  },
  locations0: {
    backgroundColor:'#FFFFFF'
  },
  locations1: {
    backgroundColor:'#EFF5F7'
  },
  locations2: {
    backgroundColor:'#BFDCE6'
  },
  locations0Hovered: {
    border:'3px solid #065695!important'
  },
  locations1Hovered: {
    border:'3px solid #065695!important'
  },
  locations2Hovered: {
    border:'3px solid #065695!important'
  },
  locations0Selected: {
    borderColor:'#BFDCE6'
  },
  locations1Selected: {
    backgroundColor:'#FFFFFF'
  },
  locations2Selected: {
    backgroundColor:'#EFF5F7'
  },
  PricingWidgetTextColor: {
    color: grayColor[6],
    cursor: "pointer"
  },
  PricingWidgetTextColorActive: {
    color: primaryColour[900],
  },
  pricingSwitch: {
    "& .MuiSwitch-colorPrimary .Mui-checked, & .MuiSwitch-colorPrimary": {
      color: primaryColour[900],
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track, & .MuiSwitch-colorPrimary + .MuiSwitch-track": {
      backgroundColor: '#075695',
      width: 27.33,
      height: 11.33
    },
    '& .MuiSwitch-thumb': {
      width: 16,
      height: 16
    }
  },
  cancelBtn: {
    color: primaryColour[900],
    marginTop: 'auto'
  },
  currenciesWrap: {
    position: "absolute",
    right: 0,
    height: 42,
    [theme.breakpoints.down(960)]: {
      position: "relative",
      width: '100%',
      marginTop: 26,
      marginBottom: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    }
  },
  planSwitcherMobile: {
    display: 'none',
    [theme.breakpoints.down(900)]: {
      display: 'block',
      marginTop: 69,
      marginBottom: 40
    }
  },
  planSwitcherDesktop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 26,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.16)',
    height: 45,
    width: 213,
    position: 'relative',
    padding: '0 18.67px',
    [theme.breakpoints.between(860, 959.95)]: {
      padding: '0 6px',
    },
    [theme.breakpoints.down(400)]: {
      padding: '0 6px',
      width: '100%',
      maxWidth: 240
    },
    '& .billing-period-wrap *': {
      [theme.breakpoints.between(860, 959.95)]: {
        fontSize: 11+'px!important'
      },
      [theme.breakpoints.down(400)]: {
        fontSize: 11+'px!important'
      }
    }
  },
  pricingModeWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginTop: 13,
    marginBottom: 48,
  },
  planIcon: {
    width: 24,
    height: 24,
    marginBottom: 9
  },
  firstOnePricingPaper: {
    '& .plan-name': {
      color: '#AAB6D3'
    }
  },
  pricingPaper: {
    width: '100%',
    maxWidth: 400,
    paddingRight: 27,
    borderRadius: 15,
    paddingTop: 23,
    textAlign: "left",
    boxShadow: "-1px 1px 3px rgba(0,0,0,0.1)",
    boxSizing: "border-box",
    border: '1px solid #D9D9D9',
    minHeight: 715,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 28,
    paddingBottom: 24,
    backgroundColor: '#eff5f7',
    [theme.breakpoints.down(959.95)]: {
      margin: 'auto'
    },
    [theme.breakpoints.between(400, 859.95)]: {
      minWidth: 400
    },
    // [theme.breakpoints.down('xs')]: {
    //   width: '96%',
    // },
    [theme.breakpoints.between(300, 400)]: {
      padding: 15
    },
    [theme.breakpoints.down(959.95)]: {
      height: 'auto',
      minHeight: 'auto',
    },
    '& .plan-price': {
      fontSize: 40,
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: 'normal',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      height: 133,
      fontFamily: 'Inter, sans-serif',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.down('sm')]: {
        height: 62
      }
    },
    '&.contact-us': {
      '& .plan-price': {
        fontSize: 25
      },
    },
    '& .plan-main-characteristic': {
      marginBottom: 77,
      [theme.breakpoints.down('md')]: {
        marginBottom: 31,
      }
    }
  },
  paidPlan: {
    height: 64+ 'px!important'
  },
  pricePrefix: {
    fontSize: 16,
    color: '#868080',
    marginLeft: 7
  },
  checkMarkIcon: {
    fontSize: '1.4rem!important',
    marginRight: 10,
    marginBottom: 'auto',
    // [theme.breakpoints.between(850, 1150)]: {
    //   marginRight: 13,
    // }
  },
  clearIcon: {
    marginRight: 10,
    color: '#AEAEAE!important',
    fontSize: '1.5rem !important',
    marginRight: 10,
    top: '2px !important',
  },
  featureListItemText: {
    paddingBottom: 24,
    [theme.breakpoints.between('sm', 1240)]: {
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 15,
    },
    '& .MuiTypography-displayBlock span': {
      fontSize: '16px!important',
      lineHeight: 'normal',
      textDecoration: 'underline dashed',
      color: blackColor,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '19px',
      textUnderlineOffset: '6px',
      [theme.breakpoints.between(860, 959.95)]: {
        fontSize: '12px!important',
      },
      [theme.breakpoints.down(400)]: {
        fontSize: '12px!important',
      },
      [theme.breakpoints.between(960, 1240)]: {
        fontSize: '14px!important',
      }
    }
  },
  smallButton: {
    height: 44,
    width: 223,
  },
  middleButton: {
    height: 66,
    maxWidth: 275,
    width: 'auto',
    margin: 'auto',
    display: 'flex'
  },
  largeButton: {
    height: 80
  },
  submitButton: {
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 8,
    color: whiteColor,
    textDecoration: 'none',
    maxWidth: 343,
    boxShadow: 'none',
    [theme.breakpoints.between(860, 959.95)]: {
      height: 52
    },
    [theme.breakpoints.down(859.95)]: {
      marginTop: 25
    }
  },
  submitButtonFilled: {
    transition: '1s all',
    '&:hover': {
      border: '2px solid #064D85',
      backgroundColor: whiteColor,
      color: '#064D85'
    }
  },
  submitButtonOutlined: {
    background: whiteColor,
    border: '2px solid #064D85',
    color: '#064D85',
    transition: '1s all',
    '&:hover': {
      border: '2px solid #064D85',
      backgroundColor: '#064D85',
      color: whiteColor
    }
  },
  pricingPaperRemovals: {
    minWidth: 265,
    padding: 10,
    textAlign: "center",
    boxShadow: "0 1px 4px rgba(0,0,0,0.25)",
    borderRadius: 15,
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 289,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 256,
    },
  },
  pricingPaperDesktop: {
    maxWidth: 355,
    width: '100%',
    padding: 15,
    paddingLeft: 17,
    paddingRight: 25,
    textAlign: "center",
    boxShadow: "0 1px 4px rgba(0,0,0,0.25)",
    borderRadius: 15,
    marginBottom: 28,
    boxSizing: "border-box",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 'auto',
    transition: 'all .5s',
    border:'3px solid transparent',
    cursor: 'pointer',
    position: 'relative',
    [theme.breakpoints.up("sm")]: {
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: 355,
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft:5,
      paddingRight: 5,
      height: 'auto',
      marginBottom: 16,
      minHeight: 98
    },
    height: 117,
    '&::after': {
      content: '" "',
      width: 60,
      height: 2,
      backgroundColor: '#CCE1E9',
      position: 'absolute',
      top: '50%',
      marginTop: -1,
      right: -63,
      [theme.breakpoints.down(770)]: {
        width: 30,
        right: -33,
      },
    }
  },
  pricingPaperDesktopBasic: {
    '&::after': {
      backgroundColor: '#E5D7F8',
    }

  },
  pricingRecommended: {
    backgroundColor: primaryColour[50],
  },
  priceDiscount: {
    color: grayColor[7],
  },
  helpWrap: {
    width: '100%',
    padding: 28,
    margin: 'auto',
    justifyContent: 'space-between',
    position: 'relative',
    borderRadius: '16px',
    border: '1px solid #BFDCE6',
    maxWidth: 921,
    margin: '0 30px',
    '&:before': {
      content: `" "`,
      display: "block",
      width: 234,
      height: 234,
      backgroundImage: "url(" + HelpSectionLeft + ")",
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      left: 0,
      top: 10,
      [theme.breakpoints.down("sm")]: {
        content: 'none',
      }
    },
    '&:after': {
      content: `" "`,
      width: 234,
      height: 234,
      backgroundImage: "url(" + HelpSectionRight + ")",
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      right: 0,
      top: 10,
      [theme.breakpoints.down("sm")]: {
        content: 'none',
      }
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: 'center',
      marginTop: 20,
      flexDirection: 'column'
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: 'center',
      marginTop: 20,
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    // '&:before': { //TODO findout what pourpose of this block
    //   content: `" "`,
    //   display: "block",
    //   width: 30,
    //   height: 2,
    //   backgroundColor: primaryColour[500],
    //   margin: "12px auto 0 auto",
    //   position: 'absolute',
    //   top: -37,
    //   marginLeft: -15,
    //   left: '50%',
    //   'removals-page &': {
    //     display: 'none'
    //   },
    //   [theme.breakpoints.down("xs")]: {
    //     top: -35,
    //   }
    // }
  },
  helpWrapRemoveDivider: {
    // '&:before': {
    //   content: `unset!important`,
    // }
  },
  helpTopHead: {
    fontSize: 30,
    color: blackColor,
    marginBottom: 35,
    fontWeight: 500,
    lineHeight: 1,
    [theme.breakpoints.down("md")]: {
      textAlign: 'center'
    },
    '&:after': {
      left: '50%',
      width: 30,
      height: 2,
      margin: '17px auto 0 auto',
      content: '" "',
      display: 'block',
      position: 'absolute',
      marginLeft: -15,
      backgroundColor: '#1CB2E6',
      [theme.breakpoints.down("xs")]: {
        content: 'none',
      },
    }
  },
  helpTopHeadRemovals: {
    [theme.breakpoints.down("xs")]: {
      color: whiteColor,
    }
  },
  helpSubHead: {
    fontSize: 18,
    color: primaryColour[700],
    [theme.breakpoints.up("md")]: {
      marginBottom: 32,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      textAlign: 'center'
    },
    [theme.breakpoints.down("xs")]: {
      color: primaryColour[900],
      fontSize: 16,
      textAlign: 'center'
    }
  },
  helpSubHeadRemovals: {
    [theme.breakpoints.down("xs")]: {
      color: primaryColour[700],
    }
  },
  buttonWrap: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 24
    },
    [theme.breakpoints.down("xs")]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: '1 1 100%',
      gap: 16
    }
  },
  priceDiscountDesktop: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
    fontSize: 12,
    height: 20
  },
  planName: {
    fontSize: 30,
    fontWeight: 'bold',
    color: blackColor,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 30
    }
  },
  widgetPrice: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    justifyContent: 'center',
    alignItems: 'flex-end',
    '& p': {
      margin: 0,
      fontSize: 16
    },
    '& .total-price': {
      fontSize: 15,
      '& p': {
        fontSize: 15,
      }
    }
  },
  priceFont: {
    fontSize: "1.8rem",
    fontWeight: 400,
    display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.8rem",
    }
  },
  priceOffset: {
    marginTop: 10,
  },
  top: {
    height: 375,
    [theme.breakpoints.down(959.95)]: {
      height: 'auto',
    }
  },
  middle: {
    height: 1770,
    [theme.breakpoints.between(1110, 1240)]: {
      height: 1540,
    },
    [theme.breakpoints.between(1080, 1110)]: {
      height: 1580,
    },
    [theme.breakpoints.down(860)]: {
      height: 'auto',
    }
  },
  middleCustomPlan: {
    // height: 1770,
    // [theme.breakpoints.between(1110, 1240)]: {
    //   height: 1540,
    // },
    // [theme.breakpoints.down(959.95)]: {
    //   height: 'auto',
    // }
  },
  contactUsTitle: {
    fontSize: 30,
    color: blackColor,
    marginBottom: 30,
    marginTop: 38
  },
  mainFeature: {
    textAlign: 'left',
    color: blackColor,
    marginTop: 3,
    marginBottom: 16,
    fontWeight: 400,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    '& a': {
      textDecoration: 'none'
    },
  },
  contactUsMainFeature: {
    margin: 0,
    height: 191,
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down("sm")]: {
      height: 'auto'
    }
  },
  priceFontPricingPage: {
    fontSize: "1.9rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "2.375rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.7rem",
    },
  },
  locationsAmount: {
    fontSize: 20,
    //fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    }
  },
  locationsIcon: {
    width: 66,
    height: 'auto',
    '& img': {
      [theme.breakpoints.down("sm")]: {
        maxWidth: '81%'
      }
    }
  },
  priceMainFeature: {
    fontSize: 20,
    textAlign: 'center',
    color: '#666666'
  },
  customPricingDescriptionWrap: {
    maxWidth: 361,
    marginLeft: 30,
    marginTop: 20,
    width: 'unset',
    [theme.breakpoints.down("sm")]: {
      width: 'calc(50%)',
    },
    [theme.breakpoints.down(770)]: {
      marginLeft: 15,
      height: 4
    }
  },
  customPricingPlansDescription: {
    boxShadow: "0 1px 4px rgba(0,0,0,0.25)",
    borderRadius: 15,
    maxWidth: 361,
    padding: '43px 37px 43px 37px',
    border: '1px solid #BFDCE6',
    [theme.breakpoints.down("sm")]: {
      padding: '51px 37px 31px 37px',
    },
    [theme.breakpoints.down(770)]: {
      padding: 0,
      //maxWidth: 326,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 325,
      paddingLeft: 15,
      paddingRight: 15
    }
  },
  basicPlanWrap: {
    boxShadow: "0 1px 4px rgba(229, 218, 243,0.25)",
    border:' 2px solid #e5daf3',
    borderRadius: 15,
    padding: '23px 31px 28px 31px',
    marginBottom: 25,
    maxWidth: 375,
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: '15px 17px 15px 17px',
      height: 65,
      marginBottom: 17,
    },
  },
  basicTitle: {
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 1,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      marginBottom: 8,
    },
  },
  basicText: {
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  topTitle: {
    color: '#666666',
    fontSize: 24,
    textAlign: 'center',
    fontWeight: 'normal',
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  bottomTitle: {
    color: '#666666',
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'normal',
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  featureDescription: {
    marginLeft: 15,
    [theme.breakpoints.up("md")]: {
      marginBottom: '2px!important',
      marginLeft: 20,
      '& .MuiTypography-body2': {
        fontSize: 17,
        lineHeight: 1.75
      }
    }
  },
  featureDescriptionIcon: {
    [theme.breakpoints.up("md")]: {
      fontSize: 24
    }
  },
  priceListDesktop: {
    //maxWidth: 165,
    margin: "0 auto",
    color: grayColor[7],
    paddingTop: 24,
    paddingBottom: 24,
    "& .MuiListItem-dense": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
      paddingBottom: 3,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 25,
    },
    "& .MuiSvgIcon-root": {
      color: primaryColour[500],
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 14,
      paddingBottom: 14,
    },
  },
  priceListMobile: {
    maxWidth: 300,
    margin: "0 auto",
    color: grayColor[7],
    paddingTop: 24,
    paddingBottom: 24,
    "& .MuiListItem-dense": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
      paddingBottom: 3,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 25,
    },
    "& .MuiSvgIcon-root": {
      color: primaryColour[500],
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem",
    }
  },
  priceList: {
    margin: "0 auto",
    color: grayColor[7],
    paddingTop: 0,
    paddingBottom: 24,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 49,
    '& .MuiListItem-root': {
      position: 'relative'
    },
    [theme.breakpoints.between(950, 1200)]: {
      maxWidth: 'unset',
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 28
    },
    '& .MuiTypography-body2': {
      fontSize: "1.13rem",
    },
    "& .MuiListItem-dense": {
      paddingTop: 0,
      paddingBottom: 0,
      marginBottom: 7,
      [theme.breakpoints.down("xs")]: {
        marginBottom: 10
      }
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 25,
      alignSelf: 'flex-start'
    },
    "& .MuiSvgIcon-root": {
      color: primaryColour[500],
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem",
    },
  },
  priceListNoLine: {
    '&:before': {
      content: "none",
    }
  },
  serviceContainer: {
    maxWidth: 400
  },
  pricingPlanWrap: {
    paddingTop: 83,
    '& *': {
      fontFamily: 'Inter, sans-serif',
    },
    [theme.breakpoints.down(959.95)]: {
      paddingTop: 10,
    },
    '& > div': {
      [theme.breakpoints.between(860, 1240)]: { 
        maxWidth: '31%'
      },
      [theme.breakpoints.down(859.95)]: { 
        width: '100%'
      }
    },
    display: 'flex',
    [theme.breakpoints.between(860, 959.95)]: {
      width: '100vw',
      maxWidth: '100%',
      marginTop: 90
    },
    [theme.breakpoints.up("md")]: {
      gap: 16
    },
    '& .MuiSwitch-root': {
      width: 54
    },
    '& .MuiSwitch-switchBase': {
      top: 1,
      left: 4,
    },
    '& .MuiSwitch-track': {
      width: 39.33+'px!important',
      height: 12.33+'px!important',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
      top: 3,
      left: -5,
      position: 'absolute'
    }
  },
  pricingPlanWrapAdmin: {
    // [theme.breakpoints.between(351, 900)]: {
    //   width: '100vw',
    // },
    [theme.breakpoints.up("md")]: {
      gap: 16
    }
  },
  freeTrial: {
    fontSize: 16,
    fontWeight: 600,
    background: '#F3941E',
    borderRadius: 30,
    color: whiteColor,
    height: 37,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 259, 
    marginTop: 48,
    [theme.breakpoints.between(860, 1240)]: {
      fontSize: 13,
    }
  },
  paid: {
    height:375,
    [theme.breakpoints.down(959.95)]: {
      height: 'auto',
    },

  },
  popularPlanWrap: {
    [theme.breakpoints.down(860)]: {
      marginTop: 60
    },
  },
  popularOne: {
    backgroundColor: '#2BB0DE',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'absolute',
    top: -48,
    height: 48,
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: '14px 14px 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: whiteColor,
    width: '100%',
    textTransform: 'uppercase',
    fontFamily: 'Inter, sans-serif',
    // letterSpacing: '15%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      //marginLeft: 'calc(100% / 50)',
    }
  },
  isPopular: {
    marginTop: 0
  },
  CustomPricingW: {
    borderColor: primaryColour[800],
    borderRadius: 15,
    color: primaryColour[900],
  },
  CustomPricingOverline: {
    lineHeight: 1,
  },
  planSelected: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 25,
    paddingRight: 25,
    "& .MuiListItemIcon-root": {
      minWidth: 40,
      [theme.breakpoints.down("xs")]: {
        minWidth: 30,
      },
    }
  },
  planList: {
    backgroundColor: primaryColour[50],
    borderRadius: 15,
  },
  switchPadding: {
    paddingTop: 14,
    paddingBottom: 14
  },
  planListItem: {
    borderTop: "solid " + primaryColour[100] + " 1px",
  },
  planListPad: {
    padding: 0,
    "& .MuiTypography-body1": {
      lineHeight: "100%"
    },
    '& .contact-us': {
      '& .price': {
        fontSize: '1.4rem',
        fontWeight: 400
      }
    }
  },
  rootCurrency: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderLeft: '1px solid #EDEDED',
    marginLeft: 10,
    paddingLeft: 11.42,
    height: '100%',
    position: 'unset',
    '& > *': {
      color: primaryColour[700],
      fontSize: 12
    },
    '& svg': {
      color: primaryColour[700],
    },
    '& .MuiInputBase-root:before': {
      border: 'none'
    },
    '& .MuiInputBase-root:after': {
      borderBottom: 'unset'
    },
    '& .MuiInputBase-root:hover:before': {
      borderBottom: 'none!important'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'unset'
    },
    '& #currency-simple-select': {
      paddingRight: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .MuiSelect-icon': {
      right: -18
    },
    [theme.breakpoints.down(355)]: {
      paddingLeft: 5.5,
      '& #currency-simple-select': {
        paddingRight: 19
      },
      '& .MuiSelect-icon': {
        right: 0
      }
    },
  },
  currencyButton: {
    backgroundColor: '#EFF5F7',
    color: '#00437C',
    marginLeft: 3,
    marginRight: 3,
    border: '2px solid transparent!important',
    transition: '0.3s all',
    width: 80,
    height: 40,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 75,
      width: '24%',
      height: 38,
    },
    '&:hover': {
      //color: 'white',
      backgroundColor: '#EFF5F7!important',
      border: '2px solid #005695!important',
      boxShadow: 'none',
    }
  },
  selectedCurrency: {
    //backgroundColor: '#005695',
    border: '2px solid #005695!important',
    //color: whiteColor,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    '&:hover': {
      //color: 'white',
      backgroundColor: '#EFF5F7!important',
      border: '2px solid #005695!important',
      boxShadow: 'none'
    }
  },
  noteCurrency: {
    color: blueColor,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
    position: 'absolute',
    top: 53,
    left: 15
  },
  discountNote: {
    color: blueColor,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
    position: 'absolute',
    top: 53,
    left: '50%',
    marginLeft: -65
  },
  popularPricingPlan: {
    '& .MuiButton-label': {
      color: `${primaryColour[700]}!important`
    }
  },
  planNameFooter: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 29
  },
  pricingPlanContainer: {
    margin: 'auto',
    paddingBottom: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: { 
      marginTop: 10+'px!important'
    }
  }
});

export default customPricingStyle;
