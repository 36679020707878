import React, {useContext, useEffect, useMemo, useState, useRef} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import {Grid} from "@material-ui/core"
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import { Typography, Button, Link, } from "@material-ui/core";
// core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import CustomPricingPanel from "components/CustomPricing/CustomPricingPanel/CustomPricingPanel"
import CompareAllPlans from "./CompareAllPlans";
import HelpSection from "./CustomPricingPanel/CustomPricingHelpSection";
// Style
import styles from "assets/jss/material-dashboard-react/components/CustomPricingStyle";
import stylesTrnsactional from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import classNames from "classnames";
import ServicesShips from "views/common/ServicesShips";
import withWidth from '@material-ui/core/withWidth';
import SelectService from 'components/CustomPricing/CustomPricingPanel/components/SelectService';
import { allowedServices } from "./SelectService";


const useStyles = makeStyles(styles);
const useStylesTr = makeStyles(stylesTrnsactional);

// const settings = {
//   dots: true,
//   infinite: false,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   centerMode: false,
//   arrows: false,
//   variableWidth: true,
//   breakpoint: event => console.log('e', event),
//   responsive: [
//     {
//       breakpoint: 350,
//       settings:  {
//         variableWidth: false,
//       }
//     },
//     {
//       breakpoint: 600,
//       settings:  {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         initialSlide: 1

//       }
//     },
//     {
//       breakpoint: 760,
//       settings:  {
//         slidesToShow: 2,
//         slidesToScroll: 1
//       }
//     },
//     {
//       breakpoint: 900,
//       settings:  {
//         slidesToShow: 2,
//         slidesToScroll: 1,
//         centerMode: false,
//       }
//     },
//     {
//       breakpoint: 1265,
//       settings:  {
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         centerMode: false,
//       }
//     },
//     {
//       breakpoint: 500000,
//       settings:  'unslick'
//     },
//   ]
// };

const CustomPricingPanels = ({
    selectedService,
    setSelectedService,
    width,
}) => {
  const classes = useStyles();
  const classesTr = useStylesTr();
  const state = useContext(useGlobalState.State);
  const [plan, setPlan] = useState(0);
  const slider = useRef(null);

  const urlParams = new URLSearchParams(window.location.search);

  const allPlans = useMemo(() => {
    if (!['container-storage', 'self-storage', 'kiosks', 'portable-storage', 'movers'].includes(selectedService)) {
      return state.calcumatePlans.filter(plan => ['Contact-us'].includes(plan.thePlan));
    }
    return state.calcumatePlans.filter(plan => {
      if (plan.service) {
        return plan.service === selectedService;
      }
      return true;
    });
  }, [
    selectedService,
    state.currency,
    state.currentPlanPriceRec,
  ]);

  useEffect(() => {
    const value = urlParams.get('service') && allowedServices.find((elem) => elem.value === urlParams.get('service')) ? 
      urlParams.get('service') : 
      'self-storage';
    setSelectedService(value);
  }, []);

  // useEffect(() => {
  //   if (slider.current.classList && !slider.current.classList.contains('slick-initialized')) {
  //     window.$('.pricing-plans-container').slick(settings);
  //   }
  // });

  return (
    <Grid className={classes.pricingPlanContainer} id={'pricing-plan-screen'}>
      {
        ['xs', 'sm', 'md'].includes(width) ? 
          (
            <SelectService
              onChange={setSelectedService}
              selectedService={selectedService}
            />
          ) :
          (
            <ServicesShips
              onChange={setSelectedService}
              selectedService={selectedService}
            />
          )
      }
      <Grid
        container
        alignItems={'flex-start'}
        justifyContent="center"
        style={{gap: 20}}
        className={classNames(classes.pricingPlanWrap, 'pricing-plans-container')}
        ref={slider}
        data-cy={'pricing-plan-list'}
      >
        {
          allPlans.map((value, index) => {
            return (
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CustomPricingPanel
                  index={index}
                  setPlan={setPlan}
                  selected={plan}
                  {...value}
                  key={index}
                  totalAmount={allPlans.length}
                  service={selectedService}
                />
              </div>
            );
          })
        }
      </Grid>
      {
        ['sm', 'xs'].includes(width) ? (
          <HelpSection />
        ) : (
          <CompareAllPlans />
        )
      }

      {/* <Typography variant="subtitle1" component="p" className={classesTr.subTitleTwo} align="center">Prices are exclusive of any applicable taxes. Free 30 days applies only to accounts opened online and without partner involvement. ^When you pay annually up front.</Typography>*/}
    </Grid>
  );
}

export default withWidth()(CustomPricingPanels)
