export default [
  {
    category: "Subscription & Reporting",
    name: "Annual Subscription",
    plans: ['Complete'],
    value: ['10% OFF']
  },
  {
    category: "Subscription & Reporting",
    name: "Integration code",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Subscription & Reporting",
    name: "Inventory items",
    plans: ['Complete', 'Limited'],
    value: ['130+', '28']
  },
  {
    category: "Subscription & Reporting",
    name: "All languages",
    plans: ['Complete', 'Limited'],
    value: []
  },
  {
    category: "Subscription & Reporting",
    name: "All storage types",
    plans: ['Complete', 'Limited'],
    value: []
  },
  {
    category: "Subscription & Reporting",
    name: "Usage Reporting",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Subscription & Reporting",
    name: "Unlimited Calculators",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Subscription & Reporting",
    name: "Metric measurement system",
    plans: ['Complete', 'Limited'],
    value: []
  },
  {
    category: "Subscription & Reporting",
    name: "Imperial measurement system",
    plans: ['Complete', 'Limited'],
    value: []
  },
  {
    category: "Subscription & Reporting",
    name: "Display unit volume and space used by items",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Subscription & Reporting",
    name: "Display total weight of items",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Subscription & Reporting",
    name: "Display furniture item dimensions",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Subscription & Reporting",
    name: "Allow the user to play, pause and re-play the stacking animation",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Subscription & Reporting",
    name: "Allow the user to copy selected items",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Subscription & Reporting",
    name: "CTA URL",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Units",
    name: "Add custom size units",
    plans: ['Complete', 'Limited'],
    value: []
  },
  {
    category: "Units",
    name: "Import unit sizes",
    plans: ['Complete', 'Limited'],
    value: []
  },
  {
    category: "Units",
    name: "Recommend multiple unit sizes",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Units",
    name: "Add additional subheading",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Units",
    name: "Add multiple sub header",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Units",
    name: "Add CTA action on each unit size",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Advanced Settings",
    name: "Use phone number instead of URL",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Advanced Settings",
    name: "Ability to disable CTA",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Advanced Settings",
    name: "Request lead details from user",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Advanced Settings",
    name: "Include unit quantity",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Advanced Settings",
    name: "Include unit ID",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Advanced Settings",
    name: "Include unit volume",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Advanced Settings",
    name: "Include unit used space",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Advanced Settings",
    name: "Modify CTA Label",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Advanced Settings",
    name: "CTA Open in new tab options",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Categories",
    name: "Display search function in Categories",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Categories",
    name: "Add custom items",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Categories",
    name: "Display item mode (horizontal vs popup)",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Categories",
    name: "Add and modify categories",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Categories",
    name: "Change item size by 10/20/30%",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Categories",
    name: "Change item weight by 20/30%",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Presets",
    name: "Define, name, and display up to five pre-programmed lists of categories and their items when your calculator loads (e.g., 1 Bedroom, 2 Bedroom, etc.)",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Custom Branding",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Font selector",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Intro primary text",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Intro secondary text",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Upload logo to unit",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Upload unit image",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Primary colour",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Secondary colour",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Unit colour",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Solid",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Gradient",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Transparent",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Show Isometric Grid",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Button colour",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Button text colour",
    plans: ['Complete'],
    value: []
  },
  {
    category: "Design",
    name: "Category button border colour",
    plans: ['Complete'],
    value: []
  },
];

export const plans = ['Limited', 'Complete'];